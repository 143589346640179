@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 8000px) {
  .sidebar {
    z-index: 0;
  }
}
@media screen and (max-width: 1550px) {
  .dashboard {
    display: flex;
    flex-direction: column;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.date-input-wrapper {
  width: 100% !important;
}

.date-input-wrapper input[type="date"] {
  width: 100% !important;
}

.ant-picker {
  background-color: #d1d5db;
}
.ant-picker-input {
  height: 36px;
}

.custom-picker .ant-picker {
  background-color: #d1d5db;
}

.custom-picker .ant-picker-input {
  height: 36px;
}
.c-gray {
  color: #333333;
}
.lw-gray {
  color: #999999;
}
.c-blue {
  color: #007ff3;
}
.standard {
  font-weight: 400;
  font-size: 14px;
}
.menus {
  font-weight: 600;
  font-size: 14px;
}

.standard_b {
  font-weight: 700;
  font-size: 16px;
}
.tags {
  height: 32px !important;
}
.special {
  font-weight: 600;
  font-size: 13px;
  color: #ed5c0b;
  border: 1px solid #ed5c0b;
  border-radius: 4px;
  width: fit-content;
}
.special_bold {
  font-weight: 600;
  padding: 0px 2px;
  font-size: 12px;
  width: 78px;
  color: #2d4eff;
  border: 1px solid #2d4eff;
  border-radius: 4px;
  text-align: center;
}
.header-boxs {
  font-size: 16px;
  font-weight: 600;
}
.header-large {
  font-size: 18px;
}
.bold_special {
  font-size: 13px;
  font-weight: 500;
}
.no {
  font-size: 14px;
  font-weight: 500;
}
.separate {
  font-weight: 10;
  font-size: 14px;
  color: #adabab;
}
.header-table {
  font-size: 14px;
  font-weight: 700;
}

.ant-picker-range-separator {
  padding-left: 2px;
  width: 20px;
  background: url("../public/play.svg") center/cover no-repeat;
  margin-right: 12px;
}
.ant-picker-range-separator span {
  display: none;
}
.sub-btn {
  padding: 4px 15px 4px 15px;
  background-color: #007FF3;
  color: #FFFFFF  ;
  font-weight: 500;
  font-size: 16px;
}
.sub-btn:hover{
  background-color: #62B0F8;
  
}
.sub-no{
  padding: 4px 15px 4px 15px;
  color: #62B0F8;
  border-radius: 2px;
  border: 1px solid #62B0F8
  ;
}

.blue-btn {
  background-color: #007ff3;
  border-radius: 8px;
  height: 30px;
  border: 1px solid #62b0f8;
  color: #ffffff;
}
.white-btn {
  border-radius: 8px;
  height: 30px;
  color: #62b0f8;
  border: 1px solid #62b0f8;
}


.form-inputs {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dddddd;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-17cy76a-control {
  display: none;
}
.form-inputs:focus {
  border-color: #62b0f8;
  border-radius: 4px; /* Adjust the border radius as needed */
}
.css-17cy76a-control {
  z-index: 1;
}
/*COLORS*/
.Combustion-c {
  color: #2d4eff;
  border: 1px solid #2d4eff;
  border-radius: 4px;
}
.Flaring-c {
  color: #e71818;
  border: 1px solid #e71818;
  border-radius: 4px;
}
.Fugitive-c {
  color: #c014dc;
  border: 1px solid #c014dc;
  border-radius: 4px;
}
.Venting-c {
  color: #119d1f;
  border: 1px solid #119d1f;
  border-radius: 4px;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: red;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -100px; /* Adjust to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
