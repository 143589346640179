.LoginSignUpContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  top: 0%;
  left: 0;
  position: relative;
}

.LoginSignUpBox {
  border: 1px #dddddd solid;
  background-color: white;
  width: 25vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  height: 70%;
  z-index: 2;
}
.login_signUp_toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_signUp_toggle > p {
  color: rgba(0, 0, 0, 0.678);
  font: 300 1vmax "Roboto";
  transition: all 0.5s;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 150px;

}
.login_signUp_toggle > p:hover {
  color: #333333;
}
.switchingTab {
  display: flex;
  align-items: center;
  width: 300px;
  margin-bottom: 40px;
}
.switches-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LoginSignUpBox > div > div > button {
  background-color: #31ceff;
  height: 4px;
  width: 150px;
  border: none;
  transition: all 0.5s;
}

.loginForm,
.signUpForm {
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.5s;
  max-width: 300px;
}


.signUpForm {
  transform: translateY(-100%) translateX(-100vmax);
  margin-top: 85px;
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.loginForm > div > input,
.signUpForm > div > input {
  padding: 8px 12px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DDDDDD;
  border-radius: 2px;
  font: 300 0.9vmax cursive;
  height: 40px;
  outline: none;
}

.loginForm > div > svg,
.signUpForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm > a {
  color: rgba(0, 0, 0, 0.651);
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  font: 500 0.8vmax "Gill Sans";
}

.loginForm > a:hover {
  color: black;
}

#registerImage > img {
  width: 3vmax;
  border-radius: 100%;
}
#registerImage > input {
  display: flex;
  padding: 0%;
}

#registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax cursive;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#registerImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.loginBtn,
.signUpBtn {
  border: none;
  margin-top: 40px;
  background-color: #62B0F8  ;
  color: white;
  min-width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}
.header-ghg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: calc(30 / 1080 * 100vh);
  gap: calc(40 / 1080 * 100vh);
}

.loginBtn:hover {
  background-color: rgb(46, 130, 179);
}
.newRegister:hover{
 background-color: none !important;
}

.shiftToLeft {
  transform: translateX(-0%);
}
.shiftToNeutral {
  transform: translateX(0%);
}

.shiftToNeutralForm {
  transform: translateX(0%) translateY(-100%);
}

.shiftToRight {
  transform: translateX(100%);
}
.form-input{
  display: flex;
}

@media screen and (max-width: 600px) {
  .LoginSignUpContainer {
    background-color: white;
  }
  .LoginSignUpBox {
    width: 100vw;
    height: 95vh;
  }
  .login_signUp_toggle {
    height: 5vmax;
  }
  .login_signUp_toggle > p {
    font: 300 1.5vmax "Roboto";
  }

  .loginForm,
  .signUpForm {
    padding: 5vmax;
  }

  .loginForm > div > input,
  .signUpForm > div > input {
    padding: 2.5vmax 5vmax;
    font: 300 1.7vmax cursive;
  }

  .loginForm > div > svg,
  .signUpForm > div > svg {
    font-size: 2.8vmax;
  }

  .loginForm > a {
    font: 500 1.8vmax "Gill Sans";
  }

  #registerImage > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #registerImage > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax cursive;
  }

  .loginBtn,
  .signUpBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}
